@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loader-icon {
  display: inline-block;
  height: 6.4rem;
  width: 6.4rem;
  border-width: 0.2rem;
  border-style: solid;
  border-color: #ff9b00;
  position: relative;
  border-radius: 50%;
  margin-bottom: 2.5rem;
  -webkit-animation: rotate 0.3s linear infinite;
  animation: rotate 0.3s linear infinite;
}

.ql-toolbar.ql-snow {
  border: none !important;
  box-sizing: border-box;
  font-family: inherit !important;
  padding: 8px;
}
.ql-container.ql-snow {
  border: none !important;
}
